import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider, Spinner } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";

import ErrorBoundary from "./reusable/errorBoundary";
import routes from "../routes";
import { config } from "../config";

const router = createBrowserRouter(routes);

const Loader = () => (
  <section className="h-screen flex justify-center items-center bg-black/70 fixed top-0 w-full z-[100]">
    <Spinner size="xl" color="white" />
  </section>
);

const queryClient = new QueryClient();

export default function App() {
  ReactGA.initialize(config.googleTrackingId ?? "");

  return (
    <ErrorBoundary>
      <div className="circle-1"></div>
      <div className="circle-2"></div>
      <HelmetProvider>
        <ChakraProvider>
          <QueryClientProvider client={queryClient}>
            {/* <ReactQueryDevtools initialIsOpen={true} /> */}
            <Suspense fallback={<Loader />}>
              <RouterProvider router={router} />
            </Suspense>
          </QueryClientProvider>
        </ChakraProvider>
      </HelmetProvider>
    </ErrorBoundary>
  );
}
