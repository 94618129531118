import React from "react";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <section className="permission_denied">
          <div className="denied__wrapper">
            <h1>400</h1>
            <h3>
              Oh <span>no</span> seems like something went wrong.
            </h3>
            <img id="astronaut" src="/images/astronaut.svg" alt="Astronaut" />
            <img id="planet" src="/images/planet.svg" alt="Planet" />

            <button
              className="denied__link"
              onClick={() => {
                window.location.href = "/";
              }}>
              Go Home
            </button>
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
