import { lazy } from "react";
import { Helmet } from "react-helmet-async";
// import UnderConstruction from "../components/under-construction";

const Registration = lazy(() => import("../components/register"));
const RegistrationForm = lazy(() => import("../components/register/form"));
const NewsPage = lazy(() => import("../components/news/news-page"));
const Home = lazy(() => import("../components/home"));
const IP = lazy(() => import("../components/ip"));
const Layout = lazy(() => import("../components/reusable/layout"));
const Tournaments = lazy(() => import("../components/tournaments"));
const AboutPage = lazy(() => import("../components/about"));
const News = lazy(() => import("../components/news"));
const ContactUs = lazy(() => import("../components/contact"));
const Services = lazy(() => import("../components/services"));
const Details = lazy(() => import("../components/services/details"));

const routes = [
  // {
  //   path: "*",
  //   element: <UnderConstruction />,
  // },
  {
    path: "/",
    element: <Layout />,
    hasErrorBoundary: true,
    errorElement: (
      <section className="permission_denied">
        <Helmet>
          <title>404 · Upthrust Esports</title>
        </Helmet>
        <div className="denied__wrapper">
          <h1>404</h1>
          <h3>
            LOST IN <span>SPACE</span> Upthrust Esports? Hmm, looks like that page doesn't exist.
          </h3>
          <img id="astronaut" src="/images/astronaut.svg" alt="Astronaut" />
          <img id="planet" src="/images/planet.svg" alt="Planet" />

          <button
            className="denied__link"
            onClick={() => {
              window.location.href = "/";
            }}>
            Go Home
          </button>
        </div>
      </section>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "ip",
        children: [
          {
            path: ":slug",
            element: <IP />,
          },
        ],
      },
      {
        path: "register",
        children: [
          {
            index: true,
            element: <Registration />,
          },
          {
            path: ":slug",
            element: <RegistrationForm />,
          },
        ],
      },
      {
        path: "tournaments",
        element: <Tournaments />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "news",
        children: [
          {
            index: true,
            element: <News />,
          },
          {
            path: ":slug",
            element: <NewsPage />,
          },
        ],
      },
      {
        path: "contact",
        element: <ContactUs />,
      },
      {
        path: "services",
        children: [
          {
            path: ":category",
            children: [
              {
                index: true,
                element: <Services />,
              },
              {
                path: ":slug",
                element: <Details />,
              },
            ],
          },
        ],
      },
    ],
  },
];

type RoutePaths =
  | "/"
  | "/ip/:slug"
  | "/register"
  | "/register/:slug"
  | "/tournaments"
  | "/about"
  | "/news"
  | "/news/:slug"
  | "/contact"
  | "/services/:category"
  | "/services/:category/:slug";

type TitleMap = {
  [key in RoutePaths]: string;
};

const titleMap: TitleMap = {
  "/": "Home · Upthrust Esports",
  "/ip/:slug": "IP · Upthrust Esports",
  "/register": "Register · Upthrust Esports",
  "/register/:slug": "Registration Form · Upthrust Esports",
  "/tournaments": "Tournaments · Upthrust Esports",
  "/about": "About · Upthrust Esports",
  "/news": "News · Upthrust Esports",
  "/news/:slug": "News Page · Upthrust Esports",
  "/contact": "Contact · Upthrust Esports",
  "/services/:category": "Services · Upthrust Esports",
  "/services/:category/:slug": "Service Details · Upthrust Esports",
};

export const getPageTitle = (pathname: string): string => {
  const matchedPath = (Object.keys(titleMap) as RoutePaths[]).find((key) => {
    const pathParts = key.split("/").filter(Boolean);
    const pathnameParts = pathname.split("/").filter(Boolean);

    return (
      pathParts.length === pathnameParts.length &&
      pathParts.every((part, index) => part.startsWith(":") || part === pathnameParts[index])
    );
  });

  return matchedPath ? titleMap[matchedPath] : "404 Not Found";
};

export default routes;
