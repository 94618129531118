const env = process.env;

export const config = {
  googleClientId: env.REACT_APP_GOOGLE_CLIENT_ID,
  googleClientSecret: env.REACT_APP_GOOGLE_CLIENT_SECRET,

  sanityProjectId: env.REACT_APP_SANITY_PROJECT_ID,
  sanityProjectDataset: env.REACT_APP_SANITY_PROJECT_DATASET,
  sanityApiToken: env.REACT_APP_SANITY_API_TOKEN,

  googleTrackingId: env.REACT_APP_ANALYTICS_TRACKING_ID,

  emailJsServiceId: env.REACT_APP_EMAILJS_SERVICE_ID,
  emailJsTemplateId: env.REACT_APP_EMAILJS_TEMPLATE_ID,
};
